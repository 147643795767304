// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-education-template-js": () => import("./../../../src/pageTemplates/EducationTemplate.js" /* webpackChunkName: "component---src-page-templates-education-template-js" */),
  "component---src-page-templates-experience-template-js": () => import("./../../../src/pageTemplates/ExperienceTemplate.js" /* webpackChunkName: "component---src-page-templates-experience-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avatar-js": () => import("./../../../src/pages/avatar.js" /* webpackChunkName: "component---src-pages-avatar-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

